import { FEATURE_FLAGS, useFeatureFlags } from '@feature-flags/index';
import { useEffect } from 'react';

export const useRfqFakeVoiceMessaging = () => {
	const featureFlags = useFeatureFlags();

	useEffect(() => {
		if (featureFlags[FEATURE_FLAGS.RFQ_FAKE_VOICE_MESSAGING]) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment tracking
			console.log('Feature Flag tk-rfq-voice-messaging enabled');
		}
	}, [featureFlags[FEATURE_FLAGS.RFQ_FAKE_VOICE_MESSAGING]]);

	return Boolean(featureFlags[FEATURE_FLAGS.RFQ_FAKE_VOICE_MESSAGING]);
};
