import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useFeatureFlags } from '@feature-flags/use-feature-flags';
import { useAppSelector } from '@redux/hooks';

export const useStorefrontBookingTest1 = (): boolean => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const featureFlags = useFeatureFlags();

	return Boolean(
		featureFlags[FEATURE_FLAGS.STOREFRONT_BOOKING_TEST_1] && vendor?.isPaid,
	);
};
