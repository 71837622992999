import * as ExperimentActions from '@redux/experiments';
import { VRMLegalUpdateSelector } from '@redux/experiments/selectors/vrm-legal-update';
import { VRMLegalUpdateMobileSelector } from '@redux/experiments/selectors/vrm-legal-update-mobile';
import { useAppDispatch } from '@redux/hooks';
import { receiveVendor } from '@redux/vendor';
import { setSimilarVendors as setSimilarVendorsAction } from '@redux/vrm/actions';
import { getSimilarVendors as getSimilarVendorsAction } from '@redux/vrm/thunks';
import { experiments, inboxUrl } from '@settings';
import { noop } from '@utils/noop';
import Spinner from '@xo-union/tk-component-spinner';
import React, {
	createContext,
	lazy,
	Suspense,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { connect } from 'react-redux';
import type { FCWithChildren } from 'types/react-extended';
import { afterNextPaint } from '../../../helpers/after-next-paint';
import Vendor from '../../decorators/vendor';
import { useInlineRfqForm } from '../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import VrmModals from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/VrmModals/VrmModals';
import {
	VRM_SEARCH_RADIUS,
	filterVendors,
	getVendorsRequested,
	selectUserMaxGuestCount,
} from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/utils';
import { categoryHasMap } from '../../utils/categoryNameMappings';

const RfqModal = lazy(
	() =>
		import(
			/* webpackChunkName: "inlineRfq" */ '../../pages/Storefront/containers/InlineRFQ/components/RfqModal/RfqModal'
		),
);

interface RFQModalContextPropsValue {
	handleCTAClick(vendor: Vendor.Raw, uiLocation?: string): void;
}

interface RFQModalContextProps {
	code: Category.CategoryCode;
	searchLocation: Redux.Location;
	searchPageLocation: Redux.SearchPageLocation;
	pageType: Redux.Page['pageType'];
	messagedVendors: MessagedVendors.Conversations;
	similarVendors: Vendor.Similar[];
	userMaxGuestCount?: string;
	getSimilarVendors: typeof getSimilarVendorsAction;
	setSimilarVendors: typeof setSimilarVendorsAction;
	vrmLegalUpdateAssignment?: string;
	vrmLegalUpdateMobileAssignment?: string;
	reportServerSideExperiment: typeof ExperimentActions.reportServerSideExperiment;
}

const RFQModalContext = createContext<RFQModalContextPropsValue>({
	handleCTAClick: noop,
});

const ContextProvider: FCWithChildren<RFQModalContextProps> = (props) => {
	const {
		children,
		code,
		messagedVendors,
		similarVendors,
		vrmLegalUpdateAssignment,
		vrmLegalUpdateMobileAssignment,
		userMaxGuestCount,
		getSimilarVendors,
		setSimilarVendors,
		searchLocation,
		searchPageLocation,
		pageType,
		reportServerSideExperiment,
	} = props;
	const [vendorRaw, setVendorRaw] = useState<Vendor.Raw | null>(null);
	const [shouldIncludeMap, setShouldIncludeMap] = useState(false);
	const [modal, setModal] = useState<string | null>(null);
	const dispatch = useAppDispatch();

	const recommendedVendors = useMemo(() => {
		return filterVendors(messagedVendors, similarVendors, userMaxGuestCount);
	}, [messagedVendors, similarVendors, userMaxGuestCount]);

	const inlineRfqFormContext = useInlineRfqForm({
		initiator: 'Vendor Card CTA',
		headerText: 'Message Vendor',
	});

	const handleCTAClick = (rawVendor: Vendor.Raw, uiLocation?: string) => {
		afterNextPaint(() => {
			if (categoryHasMap(code)) {
				setShouldIncludeMap(true);
			}
			const conversationId = messagedVendors[rawVendor.id];
			if (conversationId) {
				window.location.href = `${inboxUrl}/${conversationId}`;
				return;
			}

			setVendorRaw(rawVendor);

			dispatch(receiveVendor({ data: { storefront: rawVendor } }));
			inlineRfqFormContext.openModal(new Vendor(rawVendor), rawVendor);

			const screenSize = window.innerHeight;
			const vendorsRequested = getVendorsRequested(
				screenSize,
				vrmLegalUpdateAssignment,
				vrmLegalUpdateMobileAssignment,
			);

			if (vrmLegalUpdateAssignment) {
				// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
				console.log('VRM Legal Update: ', vrmLegalUpdateAssignment);
				reportServerSideExperiment(experiments.VRMLegalUpdate);
			}

			if (vrmLegalUpdateMobileAssignment) {
				// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
				console.log(
					'VRM Legal Update Mobile: ',
					vrmLegalUpdateMobileAssignment,
				);
				reportServerSideExperiment(experiments.VRMLegalUpdateMobile);
			}

			rawVendor &&
				getSimilarVendors(
					rawVendor,
					vendorsRequested,
					VRM_SEARCH_RADIUS,
					searchLocation,
					searchPageLocation,
					pageType,
				);
		});
	};

	useEffect(() => {
		if (!modal) {
			setVendorRaw(null);
			setSimilarVendors([]);
		}
	}, [modal, setSimilarVendors]);

	const handleSubmittedInlineRfq = () => {
		setModal('vrm');
	};

	const closeRfqModal = () => {
		setModal(null);
		setVendorRaw(null);
	};

	const toggleVRM = (status: boolean) => {
		if (status) {
			setModal('vrm');
		}
		closeRfqModal();
	};

	const value = {
		handleCTAClick,
		shouldIncludeMap,
	};

	return (
		<RFQModalContext.Provider value={value}>
			{children}
			{vendorRaw !== null && (
				<Suspense fallback={<Spinner />}>
					<>
						<RfqModal
							context={inlineRfqFormContext}
							handleCloseInlineRfq={closeRfqModal}
							handleSubmittedInlineRfq={handleSubmittedInlineRfq}
						/>
						<VrmModals
							initiator="Vendor Card CTA"
							modal={modal}
							// @ts-expect-error recommendedVendors: Type 'Similar[]' is not assignable to type 'FESharedProfile[]'.
							recommendedVendors={recommendedVendors}
							rfqType="directory"
							setModal={setModal}
							vendorRaw={vendorRaw}
						/>
					</>
				</Suspense>
			)}
		</RFQModalContext.Provider>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	code: state.category.code,
	messagedVendors: state.messagedVendors.conversations,
	similarVendors: state.vrm.similarVendors,
	userMaxGuestCount: selectUserMaxGuestCount(state),
	searchLocation: state.location, // location for search page
	searchPageLocation: state.searchPageLocation, // location for referred search page on storefront
	pageType: state.page.pageType,
	vrmLegalUpdateAssignment: VRMLegalUpdateSelector(state),
	vrmLegalUpdateMobileAssignment: VRMLegalUpdateMobileSelector(state),
});

const mapDispatchToProps = {
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
	getSimilarVendors: getSimilarVendorsAction,
	setSimilarVendors: setSimilarVendorsAction,
};

const RFQModalContextProvider = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContextProvider);

export { RFQModalContextProvider };

export default RFQModalContext;
