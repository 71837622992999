import { FEATURE_FLAGS, useFeatureFlags } from '@feature-flags/index';
import { useEffect } from 'react';

export const useRfqFakeAiAssistantTest = () => {
	const featureFlags = useFeatureFlags();

	useEffect(() => {
		if (featureFlags[FEATURE_FLAGS.RFQ_FAKE_AI_ASSISTANT_TEST]) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment tracking
			console.log('Feature Flag tk-rfq-ia-message-generator enabled');
		}
	}, [featureFlags[FEATURE_FLAGS.RFQ_FAKE_AI_ASSISTANT_TEST]]);

	return Boolean(featureFlags[FEATURE_FLAGS.RFQ_FAKE_AI_ASSISTANT_TEST]);
};
